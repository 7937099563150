@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');


body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    height: 100%;
    display: flex;
    font-weight: 600;
}

html{
    height: 100%;
}

#root{
    width: 100%;
}

*{
    box-sizing: border-box;
}

.slick-slider{
    width: 100%;
    .slick-list{
        width: 100%;
        height: auto!important;
    }
}

.visibleMobile{
    display: none !important;
}
@media only screen and (max-width: 750px) {
   .hiddenMobile{
        display: none !important;
   }
   .visibleMobile{
        display: block !important;
    }
}

.k-editor-content .k-iframe{
    font-family: "Open Sans", sans-serif!important;
}