.MuiDataGrid-root {
    border-radius: 10px!important;
    border: none!important;

    .MuiDataGrid-columnHeaderTitle{
        color: $blue;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
    }
    .MuiDataGrid-cellContent,
    .MuiTablePagination-selectLabel,
    .MuiSelect-select-MuiInputBase-input.MuiSelect-select,
    .MuiTablePagination-displayedRows, 
    .MuiDataGrid-selectedRowCount{
        color: #808080;
        font-size: 16px;
    }
}

.MuiButton-root.MuiButton-containedPrimary {
    background-color: $blueSemiLight;
    box-shadow: none;

    &:hover{
        background-color: $blueLight;
    }
}
#topCandidatesForm .MuiOutlinedInput-input.MuiInputBase-input{
    color:#fff;
    font-size: .875rem;
    .MuiInputLabel-root{
        color: #fff;
        font-size: .875rem;
    }
}
.MuiInputLabel-root{
    color: #808080!important;
    font-size: .875rem!important;
}
.MuiSwitch-switchBase.Mui-checked{
    color: $white!important;
}
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track{
    background-color: $blueBase!important;
}
.MuiTextField-root{
    width: 100%;
}
.MuiButton-root{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 600 !important;
}
.inputDatePicker label[data-shrink="false"]{
    margin-top: -5px;
}
.textEditor *{
    flex-direction: column;
}