.sweet-alert .btn{
    padding: 0.5rem 0.9375rem;
    border-radius: 0.3125rem;
    text-decoration: none;
    position: relative;
    transition: all .2s ease;
    font-size: 1rem;
    outline: none;
}
.sweet-alert .btn:hover{
    transition: all .2s ease;
    opacity: .8;
}
.sweet-alert .btn-danger{
    background: rgb(212, 63, 58);
    color: #fff;
}
.sweet-alert .btn-success{
    background: rgb(76, 174, 76);
    color: #fff;
}
.sweet-alert .btn-warning{
    background: rgb(238, 162, 54);
    color: #fff;
}
.sweet-alert .btn-primary{
    background: #222b51;
    color: #fff;
}

.swal2-styled.swal2-confirm{
    background: #222b51!important;
    color: #fff!important;
   &:hover{
    background: #171e3a!important;
   }
}
.swal2-actions button{
    border: 0!important;
    border-radius: 0.625rem!important;
    padding: 0.61rem 1.5625rem !important;
    font-size:  1rem !important;
}